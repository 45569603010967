import React from "react"

import SEO from "../../components/seo"
import styles from "./styles.module.scss"

export default function NotFoundPage() {
  return (
    <>
      <SEO title="404" />
      <div className={styles.not_found}>
        <div className={styles.not_found__container}>
          <h1>404</h1>
        </div>
      </div>
    </>
  )
}
